<template>
  <div class="enterprise">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <!-- <el-form-item label="合作状态：">
        <dic-radio-button
          :code.sync="queryInfo.cooperationStatus"
          type-code="001"
          query-name="cooperationStatus"
          name="合作状态"
          @onChange="onChange"
        />
      </el-form-item>-->
      <!-- <el-form-item label="产业类别：">
        <dic-radio-button
          :code.sync="queryInfo.industrialChainId"
          type-code="002"
          query-name="industrialChainId"
          name="产业类别"
          @onChange="onChange"
        />
      </el-form-item> -->
      <!-- <el-form-item label="技术类别：">
        <dic-tag-button
          ref="tagButton"
          :code.sync="queryInfo.techIdName"
          query-name="techIdName"
          name="技术类别"
          tag="技术"
          key="技术类别"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="领域类别：">
        <dic-tag-button
          :code.sync="queryInfo.fieldIdName"
          query-name="fieldIdName"
          name="领域类别"
          tag="领域"
          key="领域类别"
          @onChange="onChange"
        />
      </el-form-item> -->
      <el-form-item label="所在地区：">
        <cascader-area
          :country="queryInfo.companyCountry"
          :province="queryInfo.companyProvince"
          :city="queryInfo.companyCity"
          @searchArae="searchArae"
        />
      </el-form-item>
      <!-- 新增筛选项： 赋能行业 -->
      <el-form-item label="赋能行业：">
        <dic-tag-button
          :code.sync="queryInfo.industryCategoryStr"
          query-name="industryCategoryStr"
          name="赋能行业"
          tag="行业"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.tagName)"
        >
          {{ item.name }}：{{
            queryInfo[item.tagName] ? queryInfo[item.tagName] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button type="primary" icon="jr-iconjr-icon-add" @click="addItem"
            >添加</el-button
          >

          <import-data @fileClick="fileClick" moduleName="企业数据" />

          <el-button @click="multiDelete">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <popover @popoverClick="popoverClick" />
          <div class="upload_time">
            <div @click="caretText" class="caretTime">按上传时间</div>
            <div class="upload_time_icon">
              <i
                class="el-icon-caret-top"
                :class="[
                  queryInfo.columnName == 'create_time' && queryInfo.order == 0
                    ? 'isActiveTime'
                    : 'iconColor',
                ]"
                @click="caretTop"
              />
              <i
                class="el-icon-caret-bottom"
                :class="[
                  queryInfo.columnName == 'create_time' && queryInfo.order == 1
                    ? 'isActiveTime'
                    : 'iconColor',
                ]"
                @click="caretBottom"
              />
            </div>
          </div>
        </div>
        <el-input
          placeholder="请输入产业类别、技术、所在地、合作状态、企业等"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>

      <el-table
        class="ly_table"
        :data="enterpriseTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        @selection-change="handleSelectionChange"
        @cell-dblclick="tableDoubleClick"
        :cell-style="set_cell_style"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="序号" align="center" width="70">
          <template v-slot="scope">
            <span>
              {{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="companyDataIntegrity"
          label="数据完整度"
          sortable="custom"
          align="center"
          width="120"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <el-progress
                type="circle"
                :percentage="row.companyDataIntegrity"
                :stroke-width="12"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="companyLogoLook"
          label="企业logo"
          align="center"
          width="100"
        >
          <template v-slot="{ row }">
            <div
              @click="editItem(row)"
              style="cursor: pointer"
              v-if="row.companyLogoLook"
            >
              <!-- <el-avatar shape="square" :src="row.companyLogoLook"></el-avatar> -->
              <el-image :src="row.companyLogoLook" class="headerImg" />
            </div>
            <div @click="editItem(row)" style="cursor: pointer" v-else>
              <!-- <el-avatar
                shape="square"
                :src="require('../../assets/img/pro.png')"
              ></el-avatar>-->
              <el-image
                :src="require('../../assets/img/pro.png')"
                class="headerImg"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="companyFullName" label="企业全称" align="center">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.companyFullName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="companyDescribe" label="一句话" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="100"
              trigger="hover"
              :content="scope.row.companyDescribe"
            >
              <!-- companyDescribe '' -->
              <div slot="reference">
                <div
                  v-if="
                    scope.row.companyDescribe &&
                    scope.row.companyDescribe !== null &&
                    scope.row.companyDescribe !== ''
                  "
                >
                  {{
                    scope.row.companyDescribe
                      ? scope.row.companyDescribe.substring(0, 20)
                      : ""
                  }}
                  <span v-if="scope.row.companyDescribe.length > 25">...</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="productCnt" label="产品资料" align="center">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.productCnt }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="plancnt" label="案例资料" align="center">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.planCnt }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 新增筛选项： 赋能行业 -->
        <el-table-column
          prop="industryCategory1"
          label="赋能行业"
          align="center"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.industryCategory1 !== null &&
                scope.row.industryCategory1 !== '' &&
                scope.row.industryCategory1
              "
            >
              {{ scope.row.industryCategory1.substring(0, 20) }}
              <span v-if="scope.row.industryCategory1.length > 20">...</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="领域类别" align="center" width="150">
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.fieldId &&
                scope.row.fieldId !== null &&
                scope.row.fieldId !== ''
              "
              class="tableColor"
            >
              {{ scope.row.fieldId ? scope.row.fieldId.substring(0, 20) : "" }}
              <span v-if="scope.row.fieldId.length > 20">...</span>
            </div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="技术类别" align="center" width="150">
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.techId &&
                scope.row.techId !== null &&
                scope.row.techId !== ''
              "
              class="tableColor"
            >
              {{ scope.row.techId ? scope.row.techId.substring(0, 20) : "" }}
              <span v-if="scope.row.techId.length > 20">...</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="所在地区" align="center">
          <template v-slot="{ row }">
            {{ row.companyCountry ? row.companyCountry : "" }}
            {{ row.companyProvince ? "-" + row.companyProvince : "" }}
            {{ row.companyCity ? "-" + row.companyCity : "" }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="120"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template v-slot="{ row }">
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteItem(row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        :title="dialogType"
        :visible.sync="dialogVisible"
        width="60%"
        @close="handleClose"
        center
      >
        <el-form :model="editTableObj" label-width="100px">
          <el-form-item label="技术类别：" v-if="dialogType === '技术类别'">
            <dic-checkbox-group
              ref="checkboxEnterprise"
              :code.sync="editTableObj.techId"
              tag="技术"
              key="技术"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="领域类别：" v-else>
            <dic-checkbox-group
              ref="checkboxEnterprise"
              :code.sync="editTableObj.fieldId"
              tag="领域"
              key="领域"
              @updateCode="updateCode"
            />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="saveItem">保 存</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import Popover from "@/components/Popover.vue";
import ImportData from "@/components/ImportData.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import DicTagButton from "@/components/DicTagButton.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import CascaderArea from "@/components/CascaderAreaEnter.vue";
import {
  getCompanyList,
  deleteCompany,
  updateCompany,
  // getPatentInformation
} from "@/api/enterprise";
import { exportData } from "@/utils/tool";
import { pagination } from "@/mixins/pagination";
export default {
  mixins: [pagination],

  components: {
    ButtonTable,
    ImportData,
    DicRadioButton,
    DicTagButton,
    DicCheckboxGroup,
    CascaderArea,
    Popover,
  },

  data() {
    return {
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        queryConditions: "",
        order: 1, // 0正序 1倒叙
        columnName: "create_time,update_time",
        cooperationStatus: "", // 合作状态
        industrialChainId: "", // 产业类别
        techIdName: "", // 技术类别
        techId: [],
        fieldIdName: "", // 领域类别
        fieldId: [],
        companyCountry: "", // 国家
        companyProvince: "", // 省份
        companyCity: "", // 城市
        size: "", // 所选条数
        companyCode: [], // 公司编号
        productCnt: "", //产品资料
        planCnt: "", //案例资料
        industryCategoryStr: "", //赋能行业筛选字段
        industryCategoryList: [], //赋能行业数组字段
      },
      enterpriseTable: [],
      dialogType: "技术类别",
      editTableObj: {
        techId: [], // 技术类别
        techChildrenId: [], // 技术类别二级标签
        fieldChildrenId: [], // 领域类别二级标签
        fieldId: [], // 领域类别
      },
      company: {
        code: [], // 单选删除
        multiCode: [], // 多选删除
      },
      dialogVisible: false,
      gridData: [],
      tags: [
        {
          name: "赋能行业",
          tagName: "industryCategoryStr",
          valLength: 0,
        },
        // {
        //   name: "产业类别",
        //   tagName: "industrialChainId",
        // },
        // {
        //   name: "技术类别",
        //   tagName: "techIdName",
        //   valLength: 0,
        // },
        // {
        //   name: "领域类别",
        //   tagName: "fieldIdName",
        //   valLength: 0,
        // },
      ],
      enterpriseInfo: {
        companyLogo: "", // 公司logo
        companyFullName: "", // 公司全称
        companyOperationalStatus: "", // 运营状态
        companyName: "", // 公司名称
        companyShortName: "", // 公司简称
        companyWebsite: "", // 公司网址
        companyEstablishTime: "", // 成立时间
        companyDescribe: "", // 一句话介绍
        companyBriefIntroduction: "", // 公司简介
        socialSecurityNumber: "", // 社保人数
        industrialChainId: [], // 产业类别
        techId: [], // 技术类别
        fieldId: [], // 领域类别
        cooperationStatus: "", // 合作状态
        companyFinancingRounds: "", // 融资轮次
        isQianlima: "", // 千里马公司
        isUnicorn: "", // 独角兽公司
      },
      sortData: {},
      valueI: 0,
    };
  },

  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
    enterpriseType() {
      return this.$route.params.enterpriseType;
    },
    // queryInfo() {
    //   return this.$route.params.queryInfo;
    // }
  },

  methods: {
    // async getPatentInformation() {
    //   const res = await getPatentInformation({
    //     keyWord: "北京市商汤科技开发有限公司"
    //   });
    //   console.log(res);
    // },
    fileClick() {
      this.search();
    },
    popoverClick(val) {
      if (val === "all") {
        Object.assign(this.queryInfo, { companyCode: [], size: "" });
      } else if (val == 0 && this.queryInfo.companyCode.length) {
        Object.assign(this.queryInfo, {
          companyCode: this.queryInfo.companyCode,
        });
      } else {
        Object.assign(this.queryInfo, { size: val });
      }

      exportData(
        this.queryInfo, // 后台需要的参数
        "/company/exportExcel", // 路径
        "application/msexcel", // 下载的文件类型
        this.search, // 下载完之后调查询方法
        "企业数据.xls"
      );
    },
    updateCode(val, tag) {
      this.$nextTick(() => {
        switch (tag) {
          case "领域":
            this.editTableObj.fieldChildrenId = val;
            break;

          case "技术":
            this.editTableObj.techChildrenId = val;
            break;

          default:
            break;
        }
      });
    },
    // 弹窗修改
    async saveItem() {
      //  setTimeout(()=>{})
      // setTimeout(async () => {
      if (this.editTableObj.fieldChildrenId) {
        this.editTableObj.fieldId = this.editTableObj.fieldId.concat(
          this.editTableObj.fieldChildrenId
        );
      }

      if (this.editTableObj.techChildrenId) {
        this.editTableObj.techId = this.editTableObj.techId.concat(
          this.editTableObj.techChildrenId
        );
      }

      this.editTableObj.techId = [...new Set(this.editTableObj.techId)];
      this.editTableObj.fieldId = [...new Set(this.editTableObj.fieldId)];
      const res = await updateCompany(this.editTableObj);

      if (res.code === 200) {
        this.$message.success("编辑成功");
      }

      this.dialogVisible = false;
      this.search();
      // }, 700);
    },
    // 弹窗关闭
    handleClose() {
      this.dialogVisible = false;

      this.$refs.checkboxEnterprise.clear();
    },
    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      // 解决筛选条件后点击第二条，换筛选条件后还在第二条问题
      this.queryInfo.pageNum = 1;
      let childrenVal = [];
      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === "技术类别" || name === "领域类别" || name === "赋能行业") {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0,
          });
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          });
        }
      }
      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex((el) => {
        return el.name === name;
      });

      switch (name) {
        case "赋能行业":
          this.queryInfo.industryCategoryList = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.industryCategoryList =
              this.queryInfo.industryCategoryList.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        // techName
        case "技术类别":
          this.queryInfo.techId = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.techId = this.queryInfo.techId.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        case "领域类别":
          this.queryInfo.fieldId = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.fieldId = this.queryInfo.fieldId.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        default:
          break;
      }
      this.search();
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1);

      switch (name) {
        case "fieldIdName":
          this.queryInfo.fieldIdName = "";
          this.queryInfo.fieldId = [];
          break;
        case "techIdName":
          this.queryInfo.techIdName = "";
          this.queryInfo.techId = [];
          break;
        case "industryCategoryStr":
          this.queryInfo.industryCategoryStr = "";
          this.queryInfo.industryCategoryList = [];
          break;
        default:
          this.queryInfo[name] = "";
          break;
      }
      this.search();
    },

    // 查询
    async search() {
      const res = await getCompanyList(this.queryInfo);
      if (res.code === 200) {
        this.enterpriseTable = res.data.list;
        this.enterpriseTable.forEach((el) => {
          if (el.companyDataIntegrity) {
            // el.companyDataIntegrity = Number(
            //   el.companyDataIntegrity
            // );
          } else {
            el.companyDataIntegrity = 0;
          }
        });

        this.total = res.data.total;
        //         this.enterpriseTable.forEach((item)=>{
        // item.index = (this.pageNum-1)*this.pageSize+index+1
        // })
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.company.multiCode = val.map((item) => {
        return item.companyCode;
      });

      this.queryInfo.companyCode = val.map((item) => {
        return item.companyCode;
      });
    },
    // 多选删除
    multiDelete() {
      if (this.company.multiCode.length > 1) {
        this._deleteCompany(this.company.multiCode);
      } else {
        this.$message.error("批量删除必须大于一条");
      }
    },
    // 单选删除
    deleteItem(row) {
      this.company.code.push(row.companyCode);

      this._deleteCompany(this.company.code);
    },
    _deleteCompany(params) {
      this.$confirm("确认删除吗 ?", "提示")
        .then(async () => {
          const res = await deleteCompany(params);

          if (res.code === 200) {
            this.$message.success("删除成功");
            this.search();
          }
        })
        .catch(() => {});
    },
    // 新增
    addItem() {
      this.$router.push({
        name: "EnterpriseInfo",
        params: {
          companyCode: "",
          enterpriseType: 0,
          headTabActive: 0,
          type: 2,
        },
      });
    },
    // 领域技术类别
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (column.label === "领域类别" || column.label === "技术类别") {
        return "cursor:pointer;";
      }
    },
    // 双击单元格
    tableDoubleClick(row, column) {
      if (column.label === "领域类别" || column.label === "技术类别") {
        Object.assign(this.editTableObj, row);

        this.editTableObj.fieldId = this.formatString(
          this.editTableObj.fieldId
        );

        this.editTableObj.techId = this.formatString(this.editTableObj.techId);

        this.editTableObj.industrialChainId = this.formatString(
          this.editTableObj.industrialChainId
        );

        if (column.label === "领域类别") {
          this.dialogType = "领域类别";
        } else if (column.label === "技术类别") {
          this.dialogType = "技术类别";
        }

        this.dialogVisible = true;

        if (this.$refs.checkboxEnterprise) {
          this.$refs.checkboxEnterprise.getDicData();
        }
      }
    },
    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    // 编辑
    editItem(row) {
      let text = this.$router.resolve({
        path: "/enterprise/enterpriseInfo",
        query: {
          companyCode: row.companyCode,
          enterpriseType: 1,
          queryInfo: this.queryInfo,
          id: row.id,
          headTabActive: 0,
          type: 2,
        },
      });
      window.open(text.href, "_blank");
    },
    // 点击文字切换正倒序
    caretText() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "create_time,update_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    // 正序
    caretTop() {
      // this.queryInfo.order = 0;
      // this.search();
    },
    // 倒序
    caretBottom() {
      // this.queryInfo.order = 1;
      // this.search();
    },
    // 数据完整度排序
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "companyDataIntegrity") {
        this.queryInfo.columnName = "company_data_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "create_time,update_time";
        }
      } else if (column.prop === "companyCode") {
        this.queryInfo.columnName = "company_code";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "create_time,update_time";
        }
      }
      this.search();
    },
    searchArae(val) {
      this.queryInfo.companyCountry = val.country;

      this.queryInfo.companyProvince = val.province;

      if (
        val.city != "北京" &&
        val.city != "天津" &&
        val.city != "上海" &&
        val.city != "重庆"
      ) {
        this.queryInfo.companyCity = val.city;
      }

      this.search();
    },
  },
  async created() {
    this.search();
  },
};
</script>

<style lang="less" scoped>
.enterprise {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
/deep/ .el-image__inner {
  border-radius: 5px;
}
.isActiveTime {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
</style>
